.site-logo img {
    display: inline-block;
    margin-right: 10px;
}

.site-header {
    border-top: 0px;
    padding: 3px;
}

.site-header .wrapper {
    max-width: calc(950px - (30px * 2));
}
.youtube-player {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
    margin: 5px;
  }
  
  .youtube-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: transparent;
  }
  
  .youtube-player img {
    object-fit: cover;
    display: block;
    left: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    height: auto;
    cursor: pointer;
    -webkit-transition: 0.4s all;
    -moz-transition: 0.4s all;
    transition: 0.4s all;
  }
  
  .youtube-player img:hover {
    -webkit-filter: brightness(75%);
    -moz-filter: brightness(75%);
    filter: brightness(75%);
  }
  
  .youtube-player .play {
    height: 72px;
    width: 72px;
    left: 50%;
    top: 50%;
    margin-left: -36px;
    margin-top: -36px;
    position: absolute;
    background: url("/assets/play.png") no-repeat;
    cursor: pointer;
  }
  